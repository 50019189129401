<script>
import {calculateAge} from "@/helpers/functions";

export default {
  name: "member-basic-information",
  methods: {calculateAge},
  props: {
    user: {
      type: Object,
      required: true
    }
  }
}
</script>

<template>
  <b-card>
    <b-card-header>
      <b-card-title>Information de base</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-list-group flush class="list-group-fill-success">
        <b-list-group-item v-if="!user.user" class="list-group-item-action"><i class=" bx bx-barcode align-middle me-2"></i> {{user.codes[0].name}}</b-list-group-item>
        <b-list-group-item class="list-group-item-action"><i class="bx bx-user align-middle me-2"></i> {{user.fullName}}</b-list-group-item>
        <b-list-group-item class="list-group-item-action"><i class="bx bx-phone align-middle me-2"></i> {{user.phoneNumber}}</b-list-group-item>
        <b-list-group-item class="list-group-item-action"><i class="bx bx-at align-middle me-2 "></i> {{user.email}}</b-list-group-item>
        <b-list-group-item class="list-group-item-action"><i class="bx bx-map align-middle me-2 "></i> {{user.city?.name}}, {{user.city?.region?.name}}</b-list-group-item>
        <b-list-group-item class="list-group-item-action"><i class="bx bx-calendar align-middle me-2"></i>{{ calculateAge(user.birthDate) }} ans</b-list-group-item>
<!--        <b-list-group-item class="list-group-item-action"><i class="bx bx-calendar align-middle me-2"></i>{{user.createdAt ? user.createdAt.split('T')[0] : null}}</b-list-group-item>-->
      </b-list-group>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>
<script>
export default {
  name: "user-authorised-phone-number",
  props: {
    user: {
      type: Object,
      required: true
    }
  }
}
</script>

<template>
  <b-card>
    <b-card-header>
      <b-card-title>Numéro autorisés</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-list-group flush class="list-group-fill-success">
        <b-list-group-item :key="index" v-for="(p, index) in user.authorisedPhoneNumbers" class="list-group-item-action">
          <i class="bx bx-phone align-middle me-2"></i> <span class="me-2">{{p.phoneNumber}}</span>
          <b-badge v-if="p.status === 0 || !p.status" variant="danger">Désactivé</b-badge>
          <b-badge v-else-if="p.status === 1" variant="dark">En attente</b-badge>
          <b-badge v-else-if="p.status === 2" variant="success">Actif</b-badge>
        </b-list-group-item>
      </b-list-group>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>
<script>
  export default {
    name: 'client-pagination',
    props: {
        next: {
            type: Boolean,
            default: false
        },
        currentPage: {
            type: Number,
            default: 1
        },
        totalItems: {
            type: Number,
            default: 0
        },
        itemsPerPage: {
            type: Number,
            default: 10
        }

    },
    computed: {
      pages(){
        return Math.ceil(this.totalItems / this.itemsPerPage)
      }
    },
    methods: {
      navigate(page){
        this.$emit('navigate', page)
      },
      nextPage(){
        this.$emit('nextPage')
      },
      previousPage(){
        this.$emit('previousPage')
      }
    }
  }
</script>

<template>
  <div class="align-items-center mt-4 pt-2 justify-content-between d-flex">
    <div class="flex-shrink-0">
      <div class="text-muted">
<!--        Showing <span class="fw-semibold">5</span> of-->
<!--        <span class="fw-semibold">{{ totalItems }}</span> Results-->
      </div>
    </div>
    <ul
        class="pagination pagination-separated pagination-sm mb-0"
    >
      <li class="page-item">
        <a href="#" role="button" @click="previousPage" class="page-link">←</a>
      </li>
      <li :class="{'active': page === currentPage}" v-for="(page, index) in pages" :key="index" class="page-item">
        <a href="#" role="button" class="page-link" @click="navigate(page)">{{ page }}</a>
      </li>
      <li class="page-item">
        <a href="#" role="button" @click="nextPage" class="page-link">→</a>
      </li>
    </ul>
  </div>
</template>

<style scoped>

</style>
<template>
  <div class="data-loader d-flex justify-content-center align-items-center items-center">
    <div id="">
      <div class="">
        <lottie colors="primary:#405189,secondary:#0ab39c" :options="defaultOptions"/>
        <p class="h4 mr-2 text-muted">La ressource que vous avez demandé n'existe pas</p>
        <p class="text-center"><button @click="goBack" class="btn btn-primary"><span><i class="bx bx-arrow-back"></i></span> Retour</button></p>
      </div>
    </div>
  </div>
</template>
<script>
import lottie from "@/components/widgets/lottie.vue";
import animationData from "@/components/widgets/msoeawqm.json";

export default {
  name: 'not-found',
  components: {lottie},
  data() {
    return {
      defaultOptions: { animationData: animationData, style: 'width: 100%; height: 100%;' },
    };
  },
  methods: {
    goBack(){
      this.$router.go(-1)
    }

  }
}
</script>
<style lang="css">
.data-loader{
  height: 55vh;
}
.not-found{
  font-size: 8rem;
  color: gray;
}
</style>
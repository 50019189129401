<script>
export default {
  name: "referral-informations",
  props: {
    referral: {
      type: Object,
      required: true
    },
    roleTitle: {
      type: String,
      default: 'Commercial'
    },
  },
  computed: {
    referralUrl(){
      switch (this.referral.role) {
        case 'Superviseur':
          return '/admin/statistics/' + this.referral.uniqId
        default:
          return '/admin/commercials/' + this.referral.uniqId
      }
    }
  }
}
</script>

<template>
  <b-card>
    <b-card-header>
      <b-card-title>Information du {{roleTitle}}</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-list-group flush class="list-group-fill-success">
        <b-list-group-item class="list-group-item-action">
          <i class=" bx bx-barcode align-middle me-2"></i>
          <router-link :to="referralUrl">{{referral.code}}</router-link>
        </b-list-group-item>
        <b-list-group-item class="list-group-item-action"><i class="bx bx-user align-middle me-2"></i> {{referral.fullName}}</b-list-group-item>
      </b-list-group>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>
<script>
// import {CountTo} from "vue3-count-to";
export default {
  name: 'StatCard',
  components: {},
  props: {
    title: {
      type: String,
      default: 'Utilisateurs'
    },
    amount: {
      type: String,
      default: "0"
    },
    toLink: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'dollar-circle'
    },
    iconBg: {
      type: String,
      default: 'success'
    },
    isIncrease: {
      type: Boolean,
      default: true
    },
    isMoney: {
      type: Boolean,
      default: false
    },
    percentage: {
      type: Number,
      default: 53.98
    }
  },
  data(){
    return {
      unit: ''
    }
  },
  methods: {
    growth(){
      return this.isIncrease ? 'ri-arrow-right-up-line' : 'ri-arrow-right-down-line'
    },
    formatNumber(num) {
      if (num >= 1000000) {
        this.unit = 'M'
        return (num / 1000000).toFixed(1);
      } else if (num >= 100000) {
        this.unit = 'K'
        return Math.ceil(num / 1000);
      } else {
        return num;
      }
    }
  }
}

</script>

<template>
  <div class="col-xl-4 col-md-6 ">
    <!-- card -->
    <div class="card card-animate">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <div class="flex-grow-1">
            <p class="text-uppercase fw-medium text-muted mb-0">
              {{ title }}
            </p>
          </div>
          <div class="flex-shrink-0" v-if="false" >
            <h5 :class="{'text-success': isIncrease, 'text-danger': !isIncrease}" class="fs-14 mb-0">
              <i :class="`${growth} fs-13 align-middle`"></i> {{isIncrease ? '+' : '-'}}{{percentage}} %
            </h5>
          </div>
        </div>
        <div class="d-flex align-items-end justify-content-between mt-4">
          <div>
            <h4 class="fs-22 fw-semibold ff-secondary mb-4">
<!--              <count-to-->
<!--                  :startVal="0"-->
<!--                  :endVal="formatNumber(amount)"-->
<!--                  :duration="5000"-->
<!--              />-->
              {{amount}}
              {{isMoney ? 'FCFA': ''}}
            </h4>
            <router-link v-if="toLink.length > 0" :to="toLink" class="text-decoration-underline"
            >Voir plus</router-link
            >
          </div>
          <div class="avatar-sm flex-shrink-0" >
              <span :class="`avatar-title bg-soft-${iconBg} rounded fs-3`">
                  <i :class="`bx bx-${icon} text-${iconBg}`"></i>
              </span>
          </div>
        </div>
      </div>
      <!-- end card body -->
    </div>
    <!-- end card -->
  </div>
</template>

<style scoped>

</style>